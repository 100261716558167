import { RootStore } from "../root-store";

const { makeAutoObservable } = require("mobx");

class ModalStore {

    private rootStore: RootStore;

    public isShow: boolean = false;
    public title: string = "Warning!";
    public message: any;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

}

export default ModalStore;