import mitt from 'mitt';

import ConfigStore from "./system/config-store";
import ImagesStore from "./system/images-store";
import ErrorStore from "./system/error-store";
import ModalStore from "./system/modal-store";
import LoginStore from "./auth/login-store";
import AuthStore from "./auth/auth-store"
import PageStore from "./page-store";
import NewsStore from "./news-store";
import FinanceStore from "./finance-store";
import NewSearchStore from "@store/lookup/new-search-store";
import SuperSearchStore from "@store/lookup/super-search-store";
import PremiumSearchStore from "@store/lookup/premium-search-store";
import FullzStore from "@store/fullz-store";
import ProsStore from "@store/pros-store";
import UsedStore from "@store/used-store";
import TicketsStore from "@store/tickets-store";
import StaticPageStore from "@store/static-page-store";
import AttacheStore from "@store/attache-store";
import MmnStore from "@store/mmn-store";
import EmailsStore from "@store/emails-store";

import { instance } from "@api/service";


export class RootStore {

    // эмиттер для шины сообщений
    emitter = mitt();

    configStore  = new ConfigStore(this);
    imagesStore  = new ImagesStore(this);
    attacheStore = new AttacheStore(this);

    errorStore   = new ErrorStore(this);
    modalStore   = new ModalStore(this);

    loginStore   = new LoginStore(this);
    authStore    = new AuthStore(this);

    pageStore    = new PageStore(this);
    newsStore    = new NewsStore(this);
    financeStore = new FinanceStore(this);

    newSearchStore     = new NewSearchStore(this);
    superSearchStore   = new SuperSearchStore(this);
    premiumSearchStore = new PremiumSearchStore(this);

    fullzStore   = new FullzStore(this);
    proStore     = new ProsStore(this);
    usedStore    = new UsedStore(this);

    mmnStore     = new MmnStore(this);
    emailsStore  = new EmailsStore(this);

    ticketsStore = new TicketsStore(this);

    staticPageStore = new StaticPageStore(this);

    constructor() {
        // @ts-ignore
        instance.rootStore = this;
    }
}
