import _ from "lodash";
import log from "loglevel";
import { toast } from "react-toastify";
import { RootStore } from "./root-store";
import { MSG } from "@config/messages";
import Api from "@api/finance";

const { makeAutoObservable } = require("mobx");


/**
 * Стор для работы с финансами (пополнения и транзации)
 */
class FinanceStore {

    public rootStore: RootStore;

    public _page = 1;
    public _pages = 0;
    private _toast: any;
    public _transactions: any = [];
    // текущие курсы
    public _exchange = {
        btc: MSG.MSG_LOADING,
        ltc: MSG.MSG_LOADING
    };

    get page() {
        return this._page;
    }
    set page(val) {
        this._page = val;
    }

    get pages() {
        return this._pages;
    }
    set pages(val) {
        this._pages = val;
    }

    get transactions() {
        return this._transactions;
    }
    set transactions(val) {
        this._transactions = val;
    }

    get exchange() {
        return this._exchange;
    }
    set exchange(val) {
        this._exchange = val;
    }


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    clearTransactions() {
        log.debug("clearTransactions");
        this.transactions = [];
    }

    addTransactions(list: any) {
        log.debug("addTransactions", list);
        let transactions = _.clone(this.transactions);
        list.map((transaction: any) => {
            if (!transactions.filter((i: any) => i.transaction_id === transaction.transaction_id).length) {
                transactions.push(transaction);
            }
        });
        this.transactions = transactions;
    }

    async getExchange() {
        log.debug("getExchange");
        try {
            const res = await Api.exchange();
            console.log("FINANCE", res);
            this.exchange = res.data.exchange;
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    /**
     * Получение транзакций юзера
     * @param page          Номер страницы транзакций
     * @param showNotify    Показывать ли уведомление о загрузке
     * @param force         Принудительно проверять на новые транзакции в блокчейне
     */
    async getTransactions(page: number, showNotify = false, force = false) {
        log.debug("getTransactions static-page:", page);
        try {
            toast.dismiss(this._toast);
            if (showNotify) {
                this._toast = toast.info(MSG.MSG_LOADING);
            }
            this.pages = 0;
            const res = await Api.transactions(page, force);
            console.log("TRANSACTIONS", res);
            //this.transactions = res.data.transactions;
            this.addTransactions(res.data.transactions);
            this.pages = res.data.pages;
            toast.dismiss(this._toast);
        } catch (ex: any) {
            console.log(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async regenerateWallets() {
        log.debug("regenerateWallets");
        this._toast = toast.info(MSG.MSG_LOADING);
        try {
            const res = await Api.regenerateWallets();
            if (res.data.status === 'OK') {
                await this.rootStore.pageStore.loadUserData();
            }
        } catch (ex) {
            console.log(ex);
        } finally {
            toast.dismiss(this._toast);
        }
    }

}

export default FinanceStore;