import log from "loglevel";
import moment from "moment";

class Utils {

    static dateFormat(dt: string|undefined, format_number: number = 0) {
        const formats = [
            'HH:mm DD.MM.YYYY',
            'DD.MM.YYYY HH:mm',
            'DD.MM.YYYY',
            'MM/DD/YYYY',
        ];
        try {
            return moment(dt).format(formats[format_number]);
        } catch (ex) {
            log.debug(ex);
            return dt;
        }
    }

    /**
     * Валидация поля DOB
     * @param date      Строка с датой из DOB
     */
    static isValidDate(date: string): boolean {
        log.debug("isValidDate date:", date);
        let result = true;
        if (
            /^[\d]{4}-[\d]{2}-[\d]{2}$/.test(date) ||
            /^[\d]{4}-[\d]{2}$/.test(date) ||
            /^[\d]{4}$/.test(date)
        ) {
            const arr: any[] = date.split("-");
            if (arr[2] && (arr[2] == 0 || arr[2] > 31)) {
                result = false;
            }
            if (arr[1] && (arr[1] == 0 || arr[1] > 12)) {
                result = false;
            }
            const cur_year = moment().year();
            if (arr[0] && (arr[0] == 0 || arr[0] > cur_year || cur_year - arr[0] > 100)) {
                result = false;
            }
        } else {
            result = false;
        }
        return result;
    }

    static scroll(selector: string) {
        const el = document.querySelector(selector);
        el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    static windowWidth() {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }

    static windowHeight() {
        return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    }

    static stringFormat(template: string, params: string[]) {
        for (let i = 0; i < params.length; i++) {
            const regexp = new RegExp('\\{' + i + '\\}', 'gi');
            template = template.replace(regexp, params[i]);
        }
        return template;
    };

    /**
     * Перемешивает элементы массива в случайном порядке
     * @param array
     */
    static shuffleArray(array: any[]) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }
}

export default Utils;