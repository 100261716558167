import tg from "@assets/img/icons/tg.svg";
import onion from "@assets/img/icons/onion.svg";

let Config = {
    API: '/api',
    modal_links: [
        {
            id: 0,
            icon: tg,
            link: 'https://t.me/ssn24',
            class: 'tg',
            title: 'Telegram'
        },
        {
            id: 1,
            icon: onion,
            link: 'https://ssn24.onion',
            class: '',
            title: 'Onion'
        }
    ]
}

export default Config;