import React from 'react';
import { observer } from "mobx-react-lite";

import { useStore } from "@hooks/use-store";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import styles from './Modal.module.scss';

/**
 * Модальный диалог для сообщений
 */
const Modal = () => {

    const { modalStore } = useStore();

    const hide = () => {
        modalStore.isShow = false;
    }
    return (
        <Rodal
            className={ styles.modal_dialog }
            visible={ modalStore.isShow }
            onClose={ hide.bind(this) } >

            <div className={ styles.title + " mb-2"}>{ modalStore.title }</div>
            <div className="d-flex align-items-center justify-content-center">
                <div className="w-100">
                    { modalStore.message }
                </div>
            </div>

        </Rodal>
    );

};

export default observer(Modal);
